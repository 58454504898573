import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import 'react-calendar/dist/Calendar.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { MdNavigateBefore } from "react-icons/md";
import { MdNavigateNext } from "react-icons/md";


import User from "../components/User";
import Yacht from "../components/Yacht";
import YachtNavbar from "../components/Navbar";

import { AppContext } from "../contexts/AppContext";

import "../App.css";
/*
Tasks page
- Display user informations (name, team, base) and all his tasks
- Every yacht is passed to Yacht.js
- Every task for a given yacht is passed in Yacht.js to Task.js
- Tasks are manegd in Task.js
*/
function Tasks() {
  // Access global states and variables from context
  const {
    yachaUrl,
    yachtInProgress,
    isATaskActive,
    updateIsTaskActive,
    updateYachtInProgress,
    appLanguage,
    updateAppLanguage,
  } = useContext(AppContext);

  const { t, i18n } = useTranslation();
  // Get users token.
  let token = localStorage.getItem("token")
    ? localStorage.getItem("token")
    : sessionStorage.getItem("token");

  // Build the requests url as defined by the APi
  let user_id = localStorage.getItem("user_id")
    ? localStorage.getItem("user_id")
    : sessionStorage.getItem("user_id");
  let bearer_token = "Bearer token=" + token;
  let [userTasks, setUserTasks] = useState([]);
  let [noTasks, setNoTasks] = useState(null)

  // To manage selected value in calendar
  let [today, setToday] = useState(new Date());
  today.setHours(4);
  // setToday(today);

  let [startDate, setStartDate] = useState(new Date());
  // startDate.setHours(4, 0, 0);
  let [selectedDate, setSelectedDate] = useState(new Date().getTime() / 1000);
  let [selectedDateFormated, setSelectedDateFormated] = useState(new Date());
  console.log("selectedDate: " + selectedDate);
  // selectedDate.setHours(4, 0, 0);
  console.log("selectedDate 04-00: " + selectedDate);

  let url = `${yachaUrl}/yacha/checklist/list/?user_id=${user_id}&day=${selectedDate}`;
  console.log("url " + url);
  console.log("bearer token " + bearer_token);

  // If token doesn't exist user is redirected to the login page
  // otherwise his tasks are fetched from the API
  const navigate = useNavigate();
  let [isAppOffline, setIsAppOffline] = useState(
    localStorage.getItem("AppOffline")
  );

  const MINUTE_MS = 6000;
  // useEffect(() => {
  //   console.log("AppLanguage: " +  appLanguage);
  //   console.log("Localstorage Language: " +  localStorage.getItem("language"));
  //   i18n.changeLanguage(localStorage.getItem("language").toLowerCase());
  // }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsAppOffline(localStorage.getItem("AppOffline"));
    }, MINUTE_MS);
    // Clear interval to prevent memory leaks.
    return () => clearInterval(interval);
  }, [isAppOffline, appLanguage]);

  useEffect(() => {
    token ? getTasks() : navigate("/");
  }, [isATaskActive, isAppOffline, selectedDate]);

  // Get users tasks from the API
  const getTasks = () => {
    axios
      .get(url, {})
      .then((res) => {
        if (res.data === false) {
          setNoTasks(true);
          console.log("NoTask is true: " + { noTasks })
          return
        }
        setUserTasks(res.data);
        setNoTasks(false);
        console.log("NoTask is false: " + { noTasks })
        console.log("getTasks = " + res.data);

      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Users tasks as Array
  const userTasksArray = Object.entries(userTasks);

  /*
  For every yacht the users tasks will be rendered
  */
  const renderedUserTasks = userTasksArray.map(([key, yacht]) => {
    const statusAction = () => {
      if (yacht.status === "completed") {
        return "yacht_status_completed";
      }
      return Object.entries(yacht.tasks).length > 1
        ? "yacht_status_open"
        : "task_action_start";
    };
    return (
      <>
        <Yacht
          key={yacht.yacht_id}
          yacht_id={yacht.yacht_id}
          user_id={user_id}
          name={yacht.name}
          type={yacht.type}
          status={yacht.status}
          yachtStatus={yacht.status}
          taskState={yacht.status}
          isTaskActive={yacht.status === "in_prorgress" ? true : false}
          yachtInfo={yacht.yacht_info}
          isCardOpen={false}
          isTaskOpen={yacht.status === "open" ? true : false}
          buttonType={
            yacht.status === "completed" ? "btn-success" : "btn-primary"
          }
          time=""
          priority={yacht.priority}
          statusAction={statusAction}
          hasMultipleTasks={
            Object.entries(yacht.tasks).length > 1 ? true : false
          }
          yacht_length={yacht.yacht_length}
          yacht_toilets={yacht.yacht_toilets}
          yacht_info={yacht.yacht_info}
          base_info={yacht.base_info}
          client_arrival={yacht.client_arrival}
          tasks={yacht.tasks}
        />
      </>
    );
  });

  // Which roles the user has are retrieved and passed to User.js to be rendered
  const userRoles = localStorage.getItem("role")
    ? JSON.parse(localStorage.getItem("role"))
    : JSON.parse(sessionStorage.getItem("role"));

  // TODO: NOW JUST for tests purposes only
  // untill implementing multilanguage support
  // Than it should be dynamically
  const isBaseManager = Object.entries(userRoles)
    .find(([key, role]) => key === 'basemanager' && role === 1);

  const getUserRoles =
    userRoles && !isBaseManager &&
    Object.entries(userRoles).map(([key, role]) => {
      if (role === 1 && key === "diver") {
        return <span className="role">{t("team_role_diver")}</span>;
      }
      if (role === 1 && key === "cleaner") {
        return <span className="role">{t("team_role_cleaner")}</span>;
      }
      if (role === 1 && key === "boatman") {
        return <span className="role">{t("team_role_boatman")}</span>;
      }
      if (role === 1 && key === "boatwasher") {
        return <span className="role">{t("team_role_boatwasher")}</span>;
      }
      if (role === 1 && key === "technician") {
        return <span className="role">{t("team_role_technician")}</span>;
      }
      if (role === 1 && key === "shuttleservice") {
        return <span className="role">{t("team_role_shuttleservice")}</span>;
      }
      if (role === 1 && key === "office") {
        return <span className="role">{t("team_role_office")}</span>;
      }
      if (role === 1 && key === "supervisor_cleaner") {
        return <span className="role">{t("team_role_supervisor_cleaner")}</span>;
      }
      if (role === 1 && key === "supervisor_boatwasher") {
        return <span className="role">{t("team_role_supervisor_boatwasher")}</span>;
      }
    });

  function updateDate(date) {
    // console.log("updateDate - selected Date: " + date)
    date.setHours(4)
    setStartDate(date)
    setSelectedDateFormated(date);
    setSelectedDate(date.getTime() / 1000)
    console.log("updateDate - selectedDate is: " + date.getTime())
    console.log("updateDate - today is: " + today.getTime())
  };

  function getPreviousDay(date) {
    const previous = new Date(date.getTime());
    previous.setDate(date.getDate() - 1);

    return previous;
  }

  function getNextDay(date) {
    const previous = new Date(date.getTime());
    previous.setDate(date.getDate() + 1);

    return previous;
  }

  // Rendering the tasks page
  console.log("Language: " + i18n.language)
  console.log("Local: " + i18n.local)
  return (
    < div className="flex-container" >
      <div className="tasks-container container">
        <YachtNavbar />
        <div className="box-user">
          <User
            forename={
              localStorage.getItem("forename")
                ? localStorage.getItem("forename")
                : sessionStorage.getItem("forename")
            }
            surname={
              localStorage.getItem("surname")
                ? localStorage.getItem("surname")
                : sessionStorage.getItem("surname")
            }
            team={isBaseManager ? <span className="role">{t("team_role_basemanager")}</span> : getUserRoles}
            base={
              localStorage.getItem("base_text")
                ? localStorage.getItem("base_text")
                : sessionStorage.getItem("base_text")
            }
            date={selectedDate}
          />
        </div>
        <div>
          <div className="calendar">
            {/* <Button variant="secondary" size="lg" onClick={() => updateDate(new Date())}>Heute</Button> */}
            <div className="datePicker-wrapper">

              <div className="previousDay">
                <MdNavigateBefore fontSize="2em" onClick={() => {
                  console.log("In previous Date: " + startDate)
                  updateDate(getPreviousDay(startDate))
                  console.log("previous Date: " + startDate)
                }}
                />
              </div>
              {/* <Calendar onChange={onChange} value={selectedDate} local={appLanguage} /> */}
              <div className="datePicker">

                <DatePicker selected={startDate} onChange={(date) => {
                  updateDate(date)
                  console.log("Datepicker - selected Date: " + date)
                }
                }
                  todayButton={t("today")}
                  locale={i18n.locale}
                  dateFormat="dd.MM.yyyy" />

              </div>
              <div className="nextDay">
                <MdNavigateNext fontSize="2em" onClick={() => {
                  console.log("In next Date: " + startDate)
                  updateDate(getNextDay(startDate))
                  console.log("Next Date: " + startDate)
                }} />
              </div>
            </div>
          </div>
        </div>
        {noTasks && (
          <div className="no-tasks card alert alert-success mb-3">
            {/* <div class="card-header">{t("no_tasks_title")}</div> */}
            <div className="card-body">
              <h4 className="alert-heading">Keine Aufgaben!</h4>
              <hr />
              {/* <p>{t("no_tasks_text")}</p> */}
              <p>Am {selectedDateFormated.toLocaleDateString()} gibt es keine Aufgaben für Dich.</p>
            </div>
          </div>
        )}
        {!noTasks && <div className="box-yachts">{renderedUserTasks}</div>}
      </div>
    </div >
  );
}

export default Tasks;
