import React, { useState, useEffect, useContext } from "react";
import Button from "react-bootstrap/Button";
import axios from "axios";
import { useTranslation } from "react-i18next";

import { AppContext } from "../contexts/AppContext";

import "../App.css";

/*
Task block:
Every task is rendered and managed by this component.
*/

// To change dynamically later
let isAnotherTaskOpen = false;

// forwardRef is used to allow parendts (yachts) to call tasks function (childs)
const Task = React.forwardRef((props, ref) => {
  // Access global states and variables from context
  const {
    yachaUrl,
    yachtInProgress,
    isATaskActive,
    updateIsTaskActive,
    updateYachtInProgress,
  } = useContext(AppContext);

  const { t } = useTranslation();

  let [isCardOpen, setIsCardOpen] = useState(props.isCardOpen);
  let [isTaskDone, setIsTaskDone] = useState(
    props.task.status === "completed" ? true : false
  );
  let [isTaskActive, setIsTaskActive] = useState(
    props.task.status === "in_progress" ? true : false
  );
  let [isTaskInProgress, setIsTaskInProgress] = useState(false);
  let [isTaskOpen, setIsTaskOpen] = useState(
    props.task.status === "open" ? true : false
  );
  let [hasMultipleTasks, setHasMultipleTasks] = useState(
    props.hasMultipleTasks
  );
  let [isTaskForOtherWorker, setIsTaskForOtherWorker] = useState(false);

  const current = new Date();
  const date = `${current.getDate()}.${current.getMonth() +
    1}.${current.getFullYear()}`;

  /* states to control in which states components/tasks are in. Used to render components dynamically*/
  let [user_id, setUser_id] = useState(props.user_id);
  let [yacht_id, setYacht_id] = useState(props.yacht_id);

  let [isTaskTodo, setIsTaskTodo] = useState(props.isTaskTodo);

  let [task_id, setTask_id] = useState(props.task.task_item_id);
  let [isTaskInProgressByOther, setIsTaskInProgressByOther] = useState(
    props.isTaskInProgressByOther
  );

  let [statusAction, setStatusAction] = useState(
    props.task.status === "in_progress"
      ? "task_action_finish"
      : "task_action_start"
  );
  let [timeSinceStart, setTimeSinceStart] = useState(
    props.task.ended && props.task.ended > 0
      ? `${new Date(props.task.ended * 1000).toLocaleString([], {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        })}`
      : null
  );

  let [iconState, setIconState] = useState(
    props.task.status === "open" ? "bi-exclamation-triangle" : ""
  );
  let [iconColor, setIconColor] = useState(
    props.task.status === "open" ? "orange" : ""
  );
  let [buttonType, setButtonType] = useState(props.buttonType);
  let [showWarning, setShowWarning] = useState(false);
  let [isStopTask, setIsStopTask] = useState(false);
  let [isFinishTask, setIsFinishTask] = useState(false);

  /*
    To calculate time since a task has been started
    Update evry minute
  */
  const MINUTE_MS = 60000;
  // Prototype: First task is already since 27 minutes in progress
  let [taskMinutes, setTaskMinutes] = useState(1);

  useEffect(() => {
    if (isTaskActive) {
      updateIsTaskActive(true);
      updateYachtInProgress(props.yacht_id);
      const interval = setInterval(() => {
        setTaskMinutes((prevTaskMinutes) => prevTaskMinutes + 1);
        if (isTaskActive) {
          setTimeSinceStart(
            ` ${t("task_inProgress_since")} ${taskMinutes} ${
              taskMinutes > 1 ? t("minutes") : t("minute")
            }`
          );
        }
      }, MINUTE_MS);
      // Clear interval to prevent memory leaks.
      return () => clearInterval(interval);
    }
  }, [timeSinceStart]);

  /* Get time whenever a task starts or ends */
  let currentTime = new Date().toLocaleString([], {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });

  let currentTimeHours = new Date().toLocaleString([], {
    hour: "2-digit",
    minute: "2-digit",
  });

  /* Functions to handel events and states changes. Later to be moved in /helper */
  // Used to hide/show dialogue askting for confirmation to finish task
  function toggleStopTask(e) {
    if (localStorage.getItem("AppOffline") === "true") {
      return;
    }
    setIsFinishTask(false);
    if (isTaskActive) {
      setIsStopTask(!isStopTask);
    }
  }

  // Stop task after confirmation
  function StopTask(e) {
    if (localStorage.getItem("AppOffline") === "true") {
      return;
    }
    if (isTaskActive) {
      setIsTaskActive(false);
      setIsStopTask(!isStopTask);
      setIsTaskTodo(true);
      setStatusAction("task_action_start");
      setTimeSinceStart(null);
      setTaskMinutes(1);
      setIconState("bi-exclamation-triangle");
      setIconColor("orange");
      setIsFinishTask(false);
      setIsTaskOpen(true);
      setShowWarning(false);
      updateIsTaskActive(false);
      updateYachtInProgress(false);
      isAnotherTaskOpen = false;
      updateTaskStatus(
        user_id,
        yacht_id,
        task_id,
        Math.floor(Date.now() / 1000),
        "aborted"
      );
    }
  }

  // What should happen if an action (start, finish..) button is clicked
  function confirmFinishTask(e) {
    if (localStorage.getItem("AppOffline") === "true") {
      return;
    }
    if (!hasMultipleTasks) {
      // Dont trigger parents to handel event
      // e.stopPropagation();
    }
    setIsStopTask(false);

    if (isTaskActive) {
      // finish task
      setIsFinishTask(true);
    } else if (isTaskOpen && !isATaskActive) {
      if (
        props.task.fixed_worker === "1" &&
        props.user_id != props.task.worker
      ) {
        setIsTaskForOtherWorker(true);
        return;
      }
      // start task
      console.log("clicked!!!");
      setIsTaskActive(true);
      setStatusAction("task_action_finish");
      setTimeSinceStart(t("task_inProgress_just_started"));
      setTaskMinutes(1);
      setIconState("");
      setIconColor("");
      setButtonType("btn-primary");
      setShowWarning(false);
      setIsTaskOpen(false);
      isAnotherTaskOpen = true;
      // static for tests purpos only
      updateIsTaskActive(true);
      updateYachtInProgress(props.yacht_id);
      updateTaskStatus(
        user_id,
        yacht_id,
        task_id,
        Math.floor(Date.now() / 1000),
        "inprogress"
      );
    } else if (!isTaskDone) {
      if (!hasMultipleTasks || (hasMultipleTasks && isCardOpen)) {
        setShowWarning(true);
      }
    }
  }

  // Used to control confirm dialogue to finish a task
  function toggleConfirmFinishTask(e) {
    if (localStorage.getItem("AppOffline" === "true")) return;

    if (isTaskActive) {
      setIsFinishTask(!isFinishTask);
    }
  }

  // Finish a task
  function finishTask(e) {
    if (localStorage.getItem("AppOffline") === "true") {
      return;
    }

    if (isTaskActive) {
      setIsFinishTask(!isFinishTask);
      setIsTaskActive(false);
      setIsTaskDone(true);
      setIsCardOpen(false);
      setTaskMinutes(1);
      setStatusAction("task_status_completed");
      setTimeSinceStart(currentTime);
      setIconState("bi-check-square");
      setIconColor("green");
      setButtonType("btn-success");
      setIsStopTask(false);
      updateIsTaskActive(false);
      updateYachtInProgress(false);
      isAnotherTaskOpen = false;
      updateTaskStatus(
        user_id,
        yacht_id,
        task_id,
        Math.floor(Date.now() / 1000),
        "completed"
      );
      if (!hasMultipleTasks) {
        props.setYachtStatus(t("yacht_status_completed"));
        props.setYachtButtonType("btn-success");
      }
    }
  }

  // Used to control if a warning message can be showed or not
  function hideWarning(e) {
    setShowWarning(false);
    setIsTaskForOtherWorker(false);
  }

  function updateTaskStatus(user_id, yacht_id, task_id, date, status) {
    let url = `${yachaUrl}/yacha/checklist/tasks/update/?user_id=${user_id}&yacht_id=${yacht_id}&task_id=${task_id}&timestamp=${date}&status=${status}`;
    console.log("Update Request: ");
    console.log(url);
    console.log("Timestamp : ");
    console.log(date);
    axios
      .get(url, {})
      .then((response) => console.log(response))
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  React.useImperativeHandle(ref, () => ({
    confirmFinishTaskChild() {
      confirmFinishTask();
    },
  }));

  function getTaskName(task) {
    if (task === "Yacht übernehmen") return t("task_name_acquisition");
    if (task === "Yacht abtauchen") return t("task_name_dive");
    if (task === "Innenreinigung") return t("task_name_insideCleaning");
    if (task === "Außenreinigung") return t("task_name_outsideCleaning");
  }
  return (
    <div>
      <div
        className={`box-rucknahme ${isTaskActive ? "task-aktive" : " "} ${
          isTaskDone ? "task-done" : " "
        }`}
      >
        <div className="box-ruckname-and-icon" onClick={toggleStopTask}>
          <div className="rucknahme">{getTaskName(props.task.name)}</div>
          <div className="stateIcon">
            <i
              className={`bi ${
                isTaskOpen ? "bi-exclamation-triangle orange" : ""
              } ${isTaskDone ? "bi-check-square green" : ""} `}
            ></i>
          </div>
        </div>

        {<div className="status">{timeSinceStart}</div>}
        {!isTaskDone && (
          <Button
            onClick={confirmFinishTask}
            type="submit"
            className={`status-action ${buttonType}`}
          >
            {t(statusAction)}
          </Button>
        )}
      </div>

      {/* Shown if clicked on an active task */}
      {isStopTask && (
        <div className="stop-task-dialog font-weight-bold">
          <div className="stop-task-question">
            {t("task_aktion_abort_confirm")}{" "}
          </div>
          <div className="stop-task-yes" onClick={StopTask}>
            {t("yes")}
          </div>
          <div className="stop-task-no" onClick={toggleStopTask}>
            {t("no")}
          </div>
        </div>
      )}
      {/* Shown only if action button "fertig" is clicked */}
      {isFinishTask && (
        <div className="finish-task-dialog font-weight-bold">
          <div className="finish-task-question">
            {t("task_aktion_finish_confirm")}{" "}
          </div>
          <div className="finish-task-yes" onClick={finishTask}>
            {t("yes")}
          </div>
          <div className="finish-task-no" onClick={toggleConfirmFinishTask}>
            {t("no")}
          </div>
        </div>
      )}

      <div className="notification">
        {showWarning && !isTaskInProgressByOther && (
          <div className="warning-task-dialog font-weight-bold">
            <div className="warning-task-text">
              {t("info_other_task_inProgress")}
            </div>
            <div className="warning-task-ok" onClick={hideWarning}>
              {t("ok")}
            </div>
          </div>
        )}
        {isTaskForOtherWorker && (
          <div className="warning-task-dialog font-weight-bold">
            <div className="warning-task-text">
              {t("info_task_for_other_worker")}
            </div>
            <div className="warning-task-ok" onClick={hideWarning}>
              {t("ok")}{" "}
            </div>
          </div>
        )}
      </div>
    </div>
  );
});

export default Task;
