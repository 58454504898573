import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import Nav from "react-bootstrap/Nav";
import { Container } from "react-bootstrap";

import ReactFlagsSelect from "react-flags-select";

import { useTranslation } from "react-i18next";

import yachaIcon from "../assets/yacha-icon.png";
import ecosailIcon from "../assets/ecosail-icon.png";

import "../App.css";

/*
Navbar block
Display the navbar whitch yachas logo and a hamburger menu to change language and log out.
*/

function YachtNavbar(props) {
  const { t, i18n } = useTranslation();

  const [selected, setSelected] = useState("");
  const navigate = useNavigate();

  // on logout clear storage and navifate to the login page again
  function logout() {
    localStorage.clear();
    sessionStorage.clear();
    navigate("/");
  }

  const changeLanguage = (lng) => {
    setSelected(lng);
    lng = lng === "US" ? "en" : lng;
    i18n.changeLanguage(lng.toLowerCase());
  };

  return (
    <>
      {[false].map((expand) => (
        <Navbar
          fixed="top"
          key={expand}
          className="navbar-header"
          expand={expand}
        >
          <Container fluid>
            <div className="icons">
              <img src={yachaIcon} className="yacha-icon" alt="yacha" />
              <img src={ecosailIcon} className="ecosail-icon" alt="ecosail" />
            </div>
            <Navbar.Brand href="#">
              <span className="font-weight-bold">Yacha </span>
              {t("yacha_title")}
            </Navbar.Brand>

            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <div className="icons">
                  <img src={yachaIcon} className="yacha-icon" alt="yacha" />
                  <img
                    src={ecosailIcon}
                    className="ecosail-icon"
                    alt="ecosail"
                  />
                </div>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  <span className="font-weight-bold">Yacha</span>{" "}
                  {t("yacha_title")}
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  <ReactFlagsSelect
                    defaultCountry="DE"
                    countries={["DE", "US", "ES", "HR", "PL", "RU", "UA"]}
                    selected={selected}
                    onSelect={(code) => changeLanguage(code)}
                    customLabels={{ US: "English", DE: "Deutsch" }}
                    placeholder={t("change_language")}
                  />
                  <Nav.Link className="rounded btn-primary" onClick={logout}>
                    {t("logout")}
                  </Nav.Link>
                </Nav>
                <div className="navbar-bottom">
                  <div className="copyright">
                    © 2022 – Yacht- und Charterzentrum GmbH
                  </div>
                  <div className="app-version">
                    Version: 1.01-beta0 (20.10.2022)
                  </div>
                </div>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  );
}

export default YachtNavbar;
