import React, { useState, useEffect, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { MdSignalWifiStatusbarConnectedNoInternet } from "react-icons/md";
import { useTranslation } from "react-i18next";

import AuthContextProvider from "./contexts/AuthContext";
import AppContextProvider from "./contexts/AppContext";

import Loading from "./components/Loading";

import Login from "./pages/login";
import Tasks from "./pages/tasks";

import "./App.css";

function App() {
  const { t, i18n } = useTranslation();

  let [status, setStatus] = useState();

  let token = localStorage.getItem(token);

  const NetworkStatus = () => {
    setStatus(navigator.onLine);

    useEffect(() => {
      function changeStatus() {
        setStatus(navigator.onLine);
        console.log(status);
      }
      window.addEventListener("online", changeStatus);
      window.addEventListener("offline", changeStatus);
      return () => {
        window.removeEventListener("online", changeStatus);
        window.removeEventListener("offline", changeStatus);
      };
    }, []);
    localStorage.setItem("AppOffline", !status);
    return status ? (
      ``
    ) : (
      <div className="notification offline bg-danger text-white">
        <div className="warning-task-dialog font-weight-bold">
          <MdSignalWifiStatusbarConnectedNoInternet fontSize="2em" />
          <div className="warning-task-text ">
            {t("warning_no_internet_connection_text")}
          </div>
        </div>
      </div>
    );
  };

  return (
    <Suspense fallback={<Loading />}>
    <div className="App">
      <AppContextProvider>
        <AuthContextProvider>
          <Router>
            <Routes>
              {<Route path="/" element={<Login />} />} :
              {<Route path="/tasks" element={<Tasks />} />}
            </Routes>
          </Router>
          <NetworkStatus />
        </AuthContextProvider>
      </AppContextProvider>
    </div>
    </Suspense>
  );
}

export default App;
