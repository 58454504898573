import React, { useState, useEffect, useContext, useRef } from "react";
import { Card } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { Badge } from "react-bootstrap";
import axios from "axios";
import { useTranslation } from "react-i18next";

import { AppContext } from "../contexts/AppContext";

import Task from "./Task";
import "../App.css";

let isAnotherTaskOpen = false;

function Yacht(props) {
  // Access global states and variables from context
  const {
    yachaUrl,
    yachtInProgress,
    isATaskActive,
    updateYachtInProgress,
  } = useContext(AppContext);

  const { t } = useTranslation();

  /* Create a Ref to  tasks ( child of yacht).
  This way Yacht can call functions from tasks to perform actions.*/
  const TaskRef = useRef();

  /* states to control in which states components/tasks are in. 
  Used to render components dynamically*/
  let [user_id, setUser_id] = useState(props.user_id);
  let [yacht_id, setYacht_id] = useState(props.yacht_id);
  let [isCardOpen, setIsCardOpen] = useState(props.isCardOpen);
  let [isTaskDone, setIsTaskDone] = useState(
    props.status === "completed" ? true : false
  );
  let [isTaskActive, setIsTaskActive] = useState(false);
  let [isTaskTodo, setIsTaskTodo] = useState(props.isTaskTodo);

  let [isTaskInProgressByOther, setIsTaskInProgressByOther] = useState(
    props.isTaskInProgressByOther
  );
  let [statusAction, setStatusAction] = useState(props.statusAction);
  let [timeSinceStart, setTimeSinceStart] = useState(props.time);
  let [buttonType, setButtonType] = useState(props.buttonType);
  let [showWarning, setShowWarning] = useState(false);
  let [hasMultipleTasks, setHasMultipleTasks] = useState(
    props.hasMultipleTasks
  );
  let [isYachtInProgress, setIsYachtInProgress] = useState(
    props.yachtStatus === "in_progress" ? true : false
  );

  /*
    To calculate time since a task has been started
    Update evry minute
  */
  const MINUTE_MS = 60000;
  let [taskMinutes, setTaskMinutes] = useState(1);
  useEffect(() => {
    if (isTaskActive) {
      const interval = setInterval(() => {
        setTaskMinutes((prevTaskMinutes) => prevTaskMinutes + 1);
        if (isTaskActive) {
          setTimeSinceStart(
            ` ${t("task_inProgress_since")} ${taskMinutes} ${
              taskMinutes > 1 ? t("minutes") : t("minute")
            }`
          );
        }
      }, MINUTE_MS);
      // Clear interval to prevent memory leaks.
      return () => clearInterval(interval);
    }
  }, [timeSinceStart]);

  useEffect(() => {
    if (yachtInProgress === yacht_id) {
      setIsCardOpen(true);
    }
    if (props.status === "completed") {
      setStatusAction("task_status_completed");
    }
  }, [isATaskActive, isYachtInProgress]);

  /* Functions to handel events and states changes. */
  // open/close tasks cards
  function toggleCard(e) {
    e.preventDefault();
    if (props.yachtState === "doingByOther") {
      return;
    }
    if (hasMultipleTasks && !isTaskDone) {
      setStatusAction(t("yacht_status_open"));
      setButtonType("btn-primary");
      if (yachtInProgress === yacht_id) {
        setIsCardOpen(true);
        return;
      }
    }

    setIsCardOpen(!isCardOpen);
  }

  // What should happen if an action (start, finish..) button is clicked
  function confirmFinishTask(e) {
    if (localStorage.getItem("AppOffline") === "true") {
      return;
    }
    if (!hasMultipleTasks) {
      // Yacht has only one task => Call the tasks function to handel this action
      // Dont trigger parents to handel event.
      TaskRef.current.confirmFinishTaskChild();
      e.stopPropagation();
    }
  }

  // Start rendering
  return (
    <div
      className={`card-container ${
        yacht_id === yachtInProgress && isYachtInProgress ? "task-active" : " "
      }`}
    >
      <Card
        className={`yacht card ${isCardOpen ? "card-open" : "card-closed"} ${
          props.status === "completed" ? "task-done" : " "
        } ${
          yacht_id === yachtInProgress || isYachtInProgress
            ? "task-active"
            : " "
        } ${isTaskTodo ? "todo" : " "} ${props.isTaskTodo} ${
          isTaskInProgressByOther ? "doingByOther" : " "
        } ${props.isTaskTodo}`}
      >
        <Card.Header
          className="yachtname"
          onClick={toggleCard}
          style={{ cursor: "pointer" }}
        >
          <div className="box-yachtname">
            <div className="yachtname font-weight-bold">
              {props.name} ({props.type})
            </div>
            {props.priority === "1" && (
              <div className="mode ">
                <Badge bg="danger">{t("yacht_prio")}</Badge>
              </div>
            )}
          </div>
          {!isCardOpen && (
            <div className="">
              <Button
                onClick={
                  hasMultipleTasks && !isCardOpen
                    ? toggleCard
                    : confirmFinishTask
                }
                type="submit"
                className={`yacht-state btn  ${
                  hasMultipleTasks && !isTaskDone && !isCardOpen
                    ? "btn-open"
                    : buttonType
                } font-weight-bold`}
              >
                {!hasMultipleTasks &&
                  props.status === "in_progress" &&
                  t("task_action_finish")}
                {!hasMultipleTasks &&
                  props.status === "open" &&
                  t("task_action_start")}
                {!hasMultipleTasks &&
                  props.status === "completed" &&
                  t("yacht_status_completed")}
                {hasMultipleTasks &&
                  `${
                    props.status === "completed"
                      ? t("yacht_status_completed")
                      : t(statusAction)
                  }`}
              </Button>
            </div>
          )}
          {isCardOpen && <div className="close">X</div>}
        </Card.Header>
        <div>
          {Object.entries(props.tasks).map(([taskKey, task]) => {
            let item_id = task.task_item_id;
            sessionStorage.setItem(item_id, JSON.stringify(task));
            return (
              <Task
                task={task}
                isCardOpen={isCardOpen}
                yacht_id={yacht_id}
                ref={TaskRef}
                user_id={user_id}
                setYachtStatus={setStatusAction}
                setYachtButtonType={setButtonType}
              />
            );
          })}
        </div>
        {isCardOpen && (
          <Card.Body>
            <div className="details-view">
              <div className="yacht-infos">
                <div className="yacht-length"> {props.yacht_info}</div>
                {/* <div className="yacht-length">
                  {`${t("yacht_length")}: ${props.yacht_length}m - `}
                  {props.yacht_toilets}{" "}
                  {props.yacht_toilets > 1 ? t("toilets") : t("toilet")}
                </div> */}
                <div className="marks">
                  {props.base_info
                    ? `${t("yacht_info_marks")}: ${props.base_info}`
                    : ``}
                </div>
                <div className="marks">
                  {props.base_shuttleservice
                    ? `${t("yacht_info_marks")}: ${props.base_shuttleservice}`
                    : ``}
                </div>
                <div className="marks">
                  {props.client_arrival
                    ? `${t("yacht_info_customer_arrival_time")}: ${new Date(
                        props.client_arrival * 1000
                      ).toLocaleString([], {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                      })}`
                    : ``}
                </div>
              </div>
            </div>
          </Card.Body>
        )}
      </Card>
    </div>
  );
}

export default Yacht;
