import React from "react";
import { useTranslation } from "react-i18next";

/*
User block
Display users name, roles, base and his tasks for the day
*/
function User(props) {
  const { t } = useTranslation();

  const current = new Date(props.date * 1000);
  console.log("User selectedDate: " + props.selectedDate)
  console.log("User current: " + current)
  // const date = `${current.getDate()}.${current.getMonth() +
  //   1}.${current.getFullYear()}`;


  return (
    <div className="user">
      <div className="greeting">
        <h2 className="font-weight-bolder">{t("hello")},</h2>
        <div className="username">
          {props.forename} {props.surname}
        </div>
      </div>
      <div className="user-infos">
        <div className="team">
          {t("team")}: {props.team}
        </div>
        <div className="stutzpunk">
          {t("base")}: {props.base}
        </div>
      </div>

      <div className="todayTasks">
        {/* {t("tasks_today")} - {date} : */}
        {/* <p>
          Aufgaben für den {current.toLocaleDateString()} :
        </p> */}
      </div>
    </div>
  );
}

export default User;
